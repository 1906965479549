<template>
  <page-main
    title="Languages"
    nextText="Organizations & Certifications"
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="nav('/Organizations')"
    @save="save()"
  >
    <template v-slot:aside>
      What languages do you know, both spoken and written? Add them here.
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Language:</span>
                English
              </li>
              <li>
                <span class="font-weight-bold">Proficiency:</span>
                Native
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Language #1:</span>
                English
              </li>
              <li>
                <span class="font-weight-bold">Proficiency:</span>
                Native
              </li>
              <li class="mt-4">
                <span class="font-weight-bold">Language #2:</span>
                Spanish
              </li>
              <li>
                <span class="font-weight-bold">Proficiency:</span>
                Basic
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-dual-list
            label="Languages:"
            labelOne="Language"
            labelTwo="Proficiency"
            buttonLabel="Add Language"
            :listOneOptions="baseLanguages"
            :listTwoOptions="proficiencyLevels"
            v-model="languages"
            tabindex="2"
            dataNameOne="language"
            dataNameTwo="languageProficiency"
          ></r-dual-list>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import * as uuid from "../utility/guid";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "SoftSkills",
  components: {},
  data: () => ({
    proficiencyLevels: [
      "Basic",
      "Conversant",
      "Proficient",
      "Fluent",
      "Native Speaker",
    ],
  }),
  methods: {
    save() {
      this.buildDisplay();
      this.saveLanguages();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("language", ["saveLanguages"]),
    buildDisplay() {
      let displayLanguages = this.languages
        .filter((l) => l.language.length > 0)
        .reduce((p, n) => {
          let sb = p + n.language;
          if (!Display.IsNullorWhitespace(n.languageProficiency)) {
            sb += " (" + n.languageProficiency + ")";
          }
          sb += ", ";
          return sb;
        }, "");
      displayLanguages = displayLanguages.substring(
        0,
        displayLanguages.length - 2
      );

      this.languagesDisplay = displayLanguages;

      return [displayLanguages];
    },
    validLanguages(current) {
      let setCurrent = new Set();
      this.languages.forEach((l) => {
        setCurrent.add(l.language);
      });

      return [
        current,
        ...this.baseLanguages.filter((bl) => !setCurrent.has(bl)),
      ];
    },
    removeLanguage(id) {
      this.languages = this.languages.filter((l) => l.id != id);
    },
    appendEmptyLanguage() {
      this.languages.push({
        language: "",
        languageProficiency: "",
        id: uuid.gen(),
      });
    },
  },
  mounted() {
    if (this.languages?.length === 0) {
      this.appendEmptyLanguage();
    }
  },
  computed: {
    displayValue: function () {
      return this.buildDisplay();
    },
    ...mapFields("language", [
      "languages",
      "languagesDisplay",
      "baseLanguages",
    ]),
    nextti() {
      let ti = this.languages.length * 3 + 3; // tab index for next button
      return ti.toString();
    },
    tips() {
      return [
        "When assessing your proficiency, consider your spoken and written skills combined.",
      ];
    },
  },
};
</script>
